import { graphql, PageProps } from "gatsby";
import { ContentfulRichTextGatsbyReference } from "gatsby-source-contentful/rich-text";
import React from "react";
import { AuthorData } from "../components/author";
import Footer from "../components/footer/footer";
import Layout from "../components/layout";
import SimpleNavbar from "../components/navbar/simple-navbar";
import renderBlocks, { Block } from "../components/render-blocks";
import RichText from "../components/rich-text/rich-text";
import useMetaFields, { MetaFieldsData } from "../hooks/use-meta-fields";

type PageData = {
  contentfulNewsPage: MetaFieldsData & {
    authors?: AuthorData[];
    blocksAfterBody?: Block[];
    blocksBeforeBody?: Block[];
    body?: {
      raw: string;
      references: ContentfulRichTextGatsbyReference[];
    };
    publishedAt?: string;
  };
};

type PageContextProps = {
  route: string;
};

const NewsPage = ({
  data,
  pageContext,
}: PageProps<PageData, PageContextProps>): JSX.Element => {
  const meta = useMetaFields(data.contentfulNewsPage, pageContext.route);

  const {
    contentfulNewsPage: {
      authors,
      blocksAfterBody,
      blocksBeforeBody,
      body,
      publishedAt,
    },
  } = data;

  return (
    <Layout meta={meta}>
      <div className="bg-white">
        <SimpleNavbar />
        {blocksBeforeBody &&
          renderBlocks(
            blocksBeforeBody,
            !blocksAfterBody && !body && <Footer inverse={true} />
          )}
        {body && (
          <>
            <div className="prose mx-auto px-4 md:px-0">
              <div className="flex items-center space-x-2 mb-4 text-gray-500 text-sm font-bold uppercase">
                <div>{publishedAt}</div>
                <div className="w-1 h-1 rounded-full bg-gray-900" />
                <div>
                  {authors &&
                    authors.map((author) => author.displayName).join(", ")}
                </div>
              </div>
              <h1 className="font-display tracking-wider">{meta.title}</h1>
              <div className="font-serif overflow-x-hidden">
                <RichText>{body}</RichText>
              </div>
            </div>
            {!blocksAfterBody && <Footer inverse={false} />}
          </>
        )}
        {blocksAfterBody &&
          renderBlocks(blocksAfterBody, <Footer inverse={true} />)}
      </div>
    </Layout>
  );
};

export default NewsPage;

export const query = graphql`
  query ($route: String!) {
    contentfulNewsPage(fields: { route: { eq: $route } }) {
      authors {
        displayName
        id
      }
      blocksAfterBody {
        __typename
        ...Blocks
      }
      blocksBeforeBody {
        __typename
        ...Blocks
      }
      body {
        raw
        references {
          __typename
          ... on Node {
            ... on ContentfulAsset {
              ...Asset
            }
          }
        }
      }
      metaDescription {
        metaDescription
      }
      metaImage {
        file {
          url
        }
      }
      metaTitle
      publishedAt(formatString: "LL")
    }
  }
`;
